import { FC, FormEvent, useState } from "react";
import { useNavigate } from "react-router-dom";
import { dataHttp } from "../HttpCon";

const Index: FC = () => {
    const navigate = useNavigate();
    const [username, setUsername] = useState("john.doe@bravoghana.com");
    const [error, setError] = useState("");
    const [loading, setLoading] = useState(false);
    const [step, setStep] = useState(0);
    const [companies, setCompanies] = useState([]);
    const loginFunc = () => {
        setLoading(true);
        dataHttp(
            'fgt',
            {
                username: username,
            },
            false,
            'POST').then((result: any) => {
                setError(result?.message);
            }).catch(err => {
                setError(err.getMessage());
            }).finally(() => {
                setLoading(false);
            });
    }

    const handleSubmit = (event: FormEvent) => {
        event.preventDefault();
        loginFunc();
    };

    return (
        <>
            <div className="container-xxl">
                <div className="authentication-wrapper authentication-basic container-p-y">
                    <div className="authentication-inner">
                        <div className="card">
                            <div className="card-body">

                                <div>
                                    <div className="app-brand justify-content-center">
                                        <a href="/" className="app-brand-link gap-2">
                                            <img src="assets/BravoLogo.png" height="60" />
                                        </a>
                                    </div>
                                    <h4 className="mb-2">Password Recovery</h4>
                                    <p className="mb-4">Please sign-in to your account</p>

                                    {error !== "" && <div className="alert alert-danger" role="alert">{error}</div>}
                                    <form id="formAuthentication" className="mb-3" action="" method="POST" onSubmit={() => loginFunc}>
                                        <div className="mb-3">
                                            <label className="form-label">Email or Username</label>
                                            <input
                                                type="text"
                                                className="form-control"
                                                id="email"
                                                name="email-username"
                                                placeholder="Enter your email or username"
                                                autoFocus
                                                value={username}
                                                onChange={(e) => setUsername(e.target.value)}
                                            />
                                        </div>
                                        <div className="mb-3 text-center">
                                            {loading === true ?
                                                <div className="spinner-border text-primary" role="status">
                                                    <span className="visually-hidden">Loading...</span>
                                                </div> :
                                                <button className="btn btn-primary d-grid w-100" type="submit" onClick={(e) => {
                                                    e.preventDefault();
                                                    loginFunc();
                                                }}>Submit</button>}
                                        </div>
                                    </form>

                                    <p className="text-center">
                                        <span>Back to </span>
                                        <a href="/">
                                            <span>Login</span>
                                        </a>
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

export default Index;