import { FC, FormEvent, useEffect, useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import { dataHttp } from "../HttpCon";

const Index: FC = () => {
    const navigate = useNavigate();
    const [username, setUsername] = useState("");
    const [password, setPassword] = useState("");
    const [oldpassword, setOldpassword] = useState("");
    const [searchParams, setSearchParams] = useSearchParams();
    const [errorType,setErrorType]=useState("alert alert-danger");
    
    const [error, setError] = useState("");
    const [loading, setLoading] = useState(false);
    useEffect(()=>{
        setUsername(searchParams.get("username") || "");
        setOldpassword(searchParams.get("token") || "");
    },[]);
    const loginFunc = () => {
        setLoading(true);
        dataHttp(
            'reset',
            {
                username: username,
                newpassword: password,
               oldpassword: oldpassword,
            },
            false,
            'POST').then((result: any) => {
                setErrorType(result?.code===200?'alert alert-info':'alert alert-danger');
                setError(result?.message);
            }).catch(err => {
                setError(err.getMessage());
            }).finally(() => {
                setLoading(false);
            });
    }

    const handleSubmit = (event: FormEvent) => {
        event.preventDefault();
        loginFunc();
    };

    return (
        <>
            <div className="container-xxl">
                <div className="authentication-wrapper authentication-basic container-p-y">
                    <div className="authentication-inner">
                        <div className="card">
                            <div className="card-body">

                                <div>
                                    <div className="app-brand justify-content-center">
                                        <a href="/" className="app-brand-link gap-2">
                                            <img src="assets/BravoLogo.png" height="60" />
                                        </a>
                                    </div>
                                    <h4 className="mb-2">Password Reset</h4>

                                    {error !== "" && <div className={`${errorType}`} role="alert">{error}</div>}
                                    <form id="formAuthentication" className="mb-3" action="" method="POST" onSubmit={() => loginFunc}>
                                        <div className="mb-3">
                                            <label className="form-label">Email or Username</label>
                                            <input
                                                type="text"
                                                className="form-control"
                                                id="email"
                                                name="email-username"
                                                placeholder="Enter your email or username"
                                                autoFocus
                                                disabled
                                                value={username}
                                                onChange={(e) => setUsername(e.target.value)}
                                            />
                                        </div>
                                        <div className="mb-3 form-password-toggle">
                                            <div className="d-flex justify-content-between">
                                                <label className="form-label" >New Password</label>
                                            </div>
                                            <div className="input-group input-group-merge">
                                                <input
                                                    type="password"
                                                    id="password"
                                                    className="form-control"
                                                    name="password"
                                                    placeholder="&#xb7;&#xb7;&#xb7;&#xb7;&#xb7;&#xb7;&#xb7;&#xb7;&#xb7;&#xb7;&#xb7;&#xb7;"
                                                    aria-describedby="password"
                                                    value={password}
                                                    onChange={(e) => setPassword(e.target.value)}
                                                />
                                                <span className="input-group-text cursor-pointer"><i className="bx bx-hide"></i></span>
                                            </div>
                                        </div>
                                        

                                        <div className="mb-3 text-center">
                                            {loading === true ?
                                                <div className="spinner-border text-primary" role="status">
                                                    <span className="visually-hidden">Loading...</span>
                                                </div> :
                                                <button className="btn btn-primary d-grid w-100" type="submit" onClick={(e) => {
                                                    e.preventDefault();
                                                    loginFunc();
                                                }}>Submit</button>}
                                        </div>
                                    </form>

                                    <p className="text-center">
                                        <span>Back to </span>
                                        <a href="/">
                                            <span>Login</span>
                                        </a>
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

export default Index;