import { FC, useEffect, useState } from "react";
import { dataHttp, noDataHttp } from "../HttpCon";

const TopMenu: FC = () => {
    const [active, setActive] = useState<any>("dashboard");
    const [companies, setCompanies] = useState<any[]>([]);
    const [shops, setShops] = useState<any[]>([]);
    const [user, setUser] = useState<{ [key: string]: any }>({});
    useEffect(() => {
        const userData = localStorage.getItem("user");
        if (userData !== null) {
            setUser(JSON.parse(userData));
        }

        const companies = localStorage.getItem("companies");
        if (companies !== null) {
            setCompanies(JSON.parse(companies));
        }

        const activeShop = localStorage.getItem("activeShop");
        setActive(activeShop);

        if(localStorage.getItem("shops")===null){
            dataHttp(`shops`,{
                id:localStorage.getItem("company")
            },true,'POST').then((results: any) => {
                if(results[0]!==undefined){
                    localStorage.setItem("shops",JSON.stringify(results));
                    setShops(results);
                }
            }).catch((err) => { });
        }else{
            setShops(JSON.parse(localStorage.getItem("shops") || '[]'));
        }

    }, []);

    const setActiveShop = (value: string) => {
        setActive(value);
        localStorage.setItem("activeShop", value);
        window.location.reload();
    }

    const setActiveTab = (label: string) => {
        window.location.href = `/${label}`;
    }

    return (<nav className="navbar navbar-expand-lg navbar-light bg-light">
        <div className="container-fluid">
            <div className="navbar-brand">
                <select className="form-control" onChange={(e) => setActiveShop(e.target.value)} value={active}>
                    <option value="" key={"all"}>All Shops</option>
                    {shops.map(c => <option value={c?.ShopID} key={c?.ShopID}>{c?.ShopName}</option>)}
                </select>
            </div>
            {(/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent))?
                <p>Active Company : <b>{localStorage.getItem("companyname")}</b></p>
                :
                <></>
            }
            <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
                <span className="navbar-toggler-icon"></span>
            </button>
            <div className="collapse navbar-collapse" id="navbarSupportedContent">
                {(/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)) ?<></>:
                     <p className="pt-3">Active Company : <b>{localStorage.getItem("companyname")}</b></p>
                }
                {(/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)) &&
                <ul className="mobilemenuu">
                    <li className={`menu-item ${active === 'dashboard' ? 'active' : ''}`}>
                        <a href="/" className="menu-link" onClick={() => setActiveTab("dashboard")}>
                            <i className="menu-icon tf-icons bx bx-home-circle"></i>
                            <div data-i18n="Analytics">Dashboard</div>
                        </a>
                    </li>

                    <li className="menu-header small text-uppercase">
                        <span className="menu-header-text">Reports</span>
                    </li>
                    <li className={`menu-item ${active === '/stock' ? 'active' : ''}`}>
                        <a href="#" className="menu-link"
                            onClick={() => setActiveTab("stock")}>
                            <i className="menu-icon tf-icons bx bx-data"></i>
                            <div data-i18n="Stock">Stock</div>
                        </a>
                    </li>
                    <li className={`menu-item ${active === '/sales' ? 'active' : ''}`}>
                        <a href="#" className="menu-link"
                            onClick={() => setActiveTab("sales")}>
                            <i className="menu-icon tf-icons bx bx-wallet-alt"></i>
                            <div data-i18n="Sales">Sales</div>
                        </a>
                    </li>
                    <li className={`menu-item ${active === '/payment' ? 'active' : ''}`}>
                        <a href="#" className="menu-link"
                            onClick={() => setActiveTab("payment")}>
                            <i className="menu-icon tf-icons bx bx-credit-card"></i>
                            <div data-i18n="Payment">Payment</div>
                        </a>
                    </li>
                    <li className={`menu-item ${active === '/discount' ? 'active' : ''}`}>
                        <a href="#" className="menu-link"
                            onClick={() => setActiveTab("discount")}>
                            <i className="menu-icon tf-icons bx bxs-discount"></i>
                            <div data-i18n="Discount">Discounts</div>
                        </a>
                    </li>
                </ul>
                }
                <ul className="navbar-nav flex-row align-items-center ms-auto ">
                    <li className="nav-item lh-1 me-3">
                        <a
                            className="github-button"
                        >{user?.Username}</a>
                    </li>
                    <li className="nav-item navbar-dropdown dropdown-user dropdown">
                        <a className="nav-link dropdown-toggle hide-arrow" href="javascript:void(0);" data-bs-toggle="dropdown">
                            <div className="avatar avatar-online">
                                <img src="../assets/img/avatars/1.png" className="w-px-40 h-auto rounded-circle" />
                            </div>
                        </a>
                        <ul className="dropdown-menu dropdown-menu-end">
                            <li>
                                <a className="dropdown-item" href="#">
                                    <div className="d-flex">
                                        <div className="flex-shrink-0 me-3">
                                            <div className="avatar avatar-online">
                                                <img src="../assets/img/avatars/1.png" className="w-px-40 h-auto rounded-circle" />
                                            </div>
                                        </div>
                                        <div className="flex-grow-1">
                                            <span className="fw-semibold d-block">{user?.Username}</span>
                                            <small className="text-muted">Admin</small>
                                        </div>
                                    </div>
                                </a>
                            </li>
                            <li>
                                <div className="dropdown-divider"></div>
                            </li>
                            <li>
                                <a className="dropdown-item" href="#"
                                    onClick={() => {
                                        localStorage.removeItem("loggedIn");
                                        localStorage.removeItem("token");
                                        localStorage.removeItem("user");
                                        localStorage.removeItem("companies");
                                        localStorage.removeItem("shops");
                                        localStorage.removeItem("activeShop");
                                        localStorage.removeItem("company");
                                        window.location.href = "/";
                                    }}>
                                    <i className="bx bx-power-off me-2"></i>
                                    <span className="align-middle">Log Out</span>
                                </a>
                            </li>
                        </ul>
                    </li>
                </ul>
            </div>
        </div>
    </nav>);
}

export default TopMenu;