import { FC, useEffect, useState } from "react";

const Aside: FC = () => {
    const [active, setActive] = useState<any>("dashboard");
    const [companies,setCompanies]=useState<any[]>([]);
    useEffect(()=>{
        const userData=localStorage.getItem("companies");
        if(userData!==null){
            setCompanies(JSON.parse(userData));
        }
    },[]);
    useEffect(() => {
        const activeTab: string = window.location.pathname;
        setActive(activeTab);
    }, []);

    const setActiveTab = (label: string) => {
        window.location.href = `/${label}`;
    }
    return (<aside id="layout-menu" className="layout-menu menu-vertical menu bg-menu-theme">
        <div className="app-brand demo">
            <a href="index.html" className="app-brand-link">
                <span className="app-brand-logo demo">
                    <img src="assets/BravoLogo.png" height="60"/>
                </span>
            </a>

            <a href="javascript:void(0);" className="layout-menu-toggle menu-link text-large ms-auto d-block d-xl-none">
                <i className="bx bx-chevron-left bx-sm align-middle"></i>
            </a>
        </div>
        
        <div className="menu-inner-shadow"></div>

        <ul className="menu-inner py-1">
            <li className={`menu-item ${active === 'dashboard' ? 'active' : ''}`}>
                <a href="/" className="menu-link" onClick={() => setActiveTab("dashboard")}>
                    <i className="menu-icon tf-icons bx bx-home-circle"></i>
                    <div data-i18n="Analytics">Dashboard</div>
                </a>
            </li>

            <li className="menu-header small text-uppercase">
                <span className="menu-header-text">Reports</span>
            </li>
            <li className={`menu-item ${active === '/stock' ? 'active' : ''}`}>
                <a href="#" className="menu-link"
                    onClick={() => setActiveTab("stock")}>
                    <i className="menu-icon tf-icons bx bx-data"></i>
                    <div data-i18n="Stock">Stock</div>
                </a>
            </li>
            <li className={`menu-item ${active === '/sales' ? 'active' : ''}`}>
                <a href="#" className="menu-link"
                    onClick={() => setActiveTab("sales")}>
                    <i className="menu-icon tf-icons bx bx-wallet-alt"></i>
                    <div data-i18n="Sales">Sales</div>
                </a>
            </li>
            <li className={`menu-item ${active === '/payment' ? 'active' : ''}`}>
                <a href="#" className="menu-link"
                    onClick={() => setActiveTab("payment")}>
                    <i className="menu-icon tf-icons bx bx-credit-card"></i>
                    <div data-i18n="Payment">Payment</div>
                </a>
            </li>
            <li className={`menu-item ${active === '/discount' ? 'active' : ''}`}>
                <a href="#" className="menu-link"
                    onClick={() => setActiveTab("discount")}>
                    <i className="menu-icon tf-icons bx bxs-discount"></i>
                    <div data-i18n="Discount">Discounts</div>
                </a>
            </li>
        </ul>
    </aside>);
}

export default Aside;