import { FC, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { AddLibrary, dataHttp, noDataHttp } from "../HttpCon";
import Aside from '../components/Aside';
import TopMenu from '../components/TopMenu';
import Footer from "../components/Footer";

declare global {
    interface Window {
        sendAlert: any;
    }
}
const Index: FC = () => {
    const navigate = useNavigate();
    const [error, setError] = useState("");
    const [loading, setLoading] = useState(false);
    const [dataSet, setDataSet] = useState([]);
    const [sDate, setSDate] = useState("");
    const [eDate, setEDate] = useState("");
    const activeShop = localStorage.getItem("activeShop") || "";
    const [payload, setPayload] = useState<any>({});
    const [filterList, setFilterList] = useState<any>([]);

    useEffect(() => {
        loadData();
    }, []);

    const loadData = () => {
        setLoading(true);
        const payload: any = {};
        payload['CoID'] = localStorage.getItem("company");
        if (!["", null].includes(activeShop))
            payload['ShopID'] = activeShop;
        dataHttp('inventory', payload, true, 'POST').then((results: any) => {
            setDataSet(results?.results || []);
            setFilterList(results?.filters || []);
            setTimeout(() => {
                window.sendAlert();
            }, 500);
        }).finally(() => {
            setLoading(false);
        });
    }

    const filterData = () => {
        window.reCreate();
        setLoading(true);
        const payloadData: any = payload;
        // if (sDate !== "")
        // payloadData['sDate'] = sDate;
        // if (eDate !== "")
        // payloadData['eDate'] = eDate;
        payloadData['CoID'] = localStorage.getItem("company");
        if (!["", null].includes(activeShop))
            payloadData['ShopID'] = activeShop;
        dataHttp('inventory', Object.fromEntries(Object.entries(payloadData).filter(([key, value]) => value !== '')), true, 'POST').then((results: any) => {
            setDataSet(results.results);
            setTimeout(() => {
                window.sendAlert();
            }, 500);
        }).finally(() => {
            setLoading(false);
        });
    }
    console.log(filterList);

    return (
        <>
            <div className="layout-wrapper layout-content-navbar">
                <div className="layout-container">

                    <Aside />

                    <div className="layout-page">

                        <TopMenu />

                        <div className="content-wrapper">

                            <div className="container-xxl flex-grow-1 container-p-y">
                                <div className="row">
                                    <div className="col-lg-12 mb-4 order-0">
                                        <div className="card mb-4">
                                            <div className="d-flex align-items-end row">
                                                <div className="col-sm-12">
                                                    <div className="card-body">
                                                        <h5 className="card-title text-primary">Filter</h5>
                                                        <hr />
                                                        <p className="text-secondary  mt-4">Filtering Records</p>
                                                        <div className="row mb-4">
                                                            {filterList.map((filters: any,index:number) => {
                                                                return (<div className="col" key={`filters${index}`}>
                                                                <label>{filters.label}</label>
                                                                <select
                                                                    className="form-control"
                                                                    onChange={(e) => {
                                                                        const currentPayload: any = {};
                                                                        currentPayload[filters.column] = e.target.value;
                                                                        setPayload({ ...payload, ...currentPayload });
                                                                    }}
                                                                    value={payload[filters]}>
                                                                    <option value="">All {filters.label}</option>
                                                                    {filters?.data.map((o: any,i:number) => <option key={`options${i}`} value={o[Object.keys(o)[0]]}>{o[Object.keys(o)[0]]}</option>)}  
                                                                </select>
                                                            </div>);
                                                            })}
                                                            <div className="col-auto">
                                                                <label>.</label>
                                                                <button className="btn btn-primary d-grid w-100" type="button" onClick={() => filterData()}>Filter</button>
                                                            </div>
                                                            <div className="col-auto">
                                                                <label>.</label>
                                                                <button className="btn btn-primary d-grid w-100" type="button" onClick={() => window.location.href = "/stock"}>Show All</button>
                                                            </div>
                                                        </div>

                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                        <div className="card">
                                            <div className="d-flex align-items-end row">
                                                <div className="col-sm-12">
                                                    <div className="card-body">
                                                        <h5 className="card-title text-primary">Stocks</h5>
                                                        <hr />
                                                        {loading === true &&
                                                            <div className="text-center">
                                                                <div className="spinner-border text-primary mb-4" role="status">
                                                                    <p className="visually-hidden">Loading...</p>
                                                                </div>
                                                            </div>}
                                                        <table id="example" className="stripe" style={{ width: "100%" }}>
                                                            <thead>
                                                                <tr>
                                                                    <th>Product Name</th>
                                                                    <th>Quantity</th>
                                                                    <th>MinStock</th>
                                                                    <th>MaxStock</th>
                                                                    <th style={{ textAlign: 'center' }}>Stock Status</th>
                                                                </tr>
                                                            </thead>
                                                            <tbody>
                                                                {dataSet.map((data: any,index:number) => {
                                                                    let status = "Order";
                                                                    let statusColor = "#f00";
                                                                    if (Number(data.Quantity) >= Number(data.MinStock)) {
                                                                        if (Number(data.Quantity) <= Number(data.MaxStock)) {
                                                                            status = "OK";
                                                                            statusColor = "#0f0";
                                                                        } else {
                                                                            status = "Over";
                                                                            statusColor = "#FFFF00";
                                                                        }
                                                                    }
                                                                    return (<tr key={index}>
                                                                        <td>{data.ProdName}</td>
                                                                        <td>{Number(data.Quantity).toFixed(2)}</td>
                                                                        <td>{Number(data.MinStock).toFixed(2)}</td>
                                                                        <td>{Number(data.MaxStock).toFixed(2)}</td>
                                                                        <td style={{ backgroundColor: statusColor, textAlign: 'center' }}><p style={{ color: '#000' }}>{status}</p></td>
                                                                    </tr>);
                                                                })}
                                                            </tbody>
                                                        </table>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>


                            <Footer />

                            <div className="content-backdrop fade"></div>
                        </div>
                    </div>
                </div>

                <div className="layout-overlay layout-menu-toggle"></div>
            </div>
            {AddLibrary('../assets/js/dashboards-analytics.js')}
        </>
    );
}

export default Index;