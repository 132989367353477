import { FC } from "react";

const Footer: FC = () => {
    return (<footer className="content-footer footer bg-footer-theme">
        <div className="container-xxl d-flex flex-wrap justify-content-between py-2 flex-md-row flex-column">
            <div className="mb-2 mb-md-0">
                ©
                <script>
                    document.write(new Date().getFullYear());
                </script>
                .All rights reserved
                
            </div>
            <div>
                
            </div>
        </div>
    </footer>);
}

export default Footer;