import { FC, FormEvent, useState } from "react";
import { useNavigate } from "react-router-dom";
import { dataHttp } from "../HttpCon";

const Index: FC = () => {
    const navigate = useNavigate();
    const [username, setUsername] = useState("");
    const [password, setPassword] = useState("");
    const [error, setError] = useState("");
    const [loading, setLoading] = useState(false);
    const [step, setStep] = useState(0);
    const [companies, setCompanies] = useState([]);
    const loginFunc = () => {
        setLoading(true);
        dataHttp(
            'login',
            {
                username: username,
                password: password
            },
            false,
            'POST').then((result: any) => {
                console.log(result);
                if (result.code === 200) {
                    setStep(1);
                    // localStorage.setItem("loggedIn", "true");
                    localStorage.setItem("token", result?.token);
                    localStorage.setItem("user", JSON.stringify(result?.message));
                    localStorage.setItem("companies", JSON.stringify(result?.companies));
                    setCompanies(result?.companies);
                    // window.location.href = "/";
                } else {
                    setError(result?.message);
                }
            }).catch(err => {
                setError(err.getMessage());
            }).finally(() => {
                setLoading(false);
            });
    }

    const handleSubmit = (event: FormEvent) => {
        event.preventDefault();
        loginFunc();
    };

    return (
        <>
            <div className="container-xxl">
                <div className="authentication-wrapper authentication-basic container-p-y">
                    <div className="authentication-inner">
                        <div className="card">
                            <div className="card-body">
                                {step === 1 ?
                                    <div>
                                        <h4 className="mb-2">Select A Company</h4>
                                        <p className="mb-4">Select a company to use as default</p>
                                        <ul className="p-0 m-0">
                                            {companies.map((data: any, i: number) => {
                                                return (<li className="d-flex mb-4 pb-1" key={`colist${i}`}>
                                                    <div className="d-flex w-100 flex-wrap align-items-center justify-content-between gap-2">
                                                        <div className="me-2">
                                                            <small className="text-muted d-block mb-1">Company</small>
                                                            <h6 className="mb-0"><a style={{ cursor: 'pointer' }} onClick={() => {
                                                                localStorage.setItem("loggedIn", "true");
                                                                localStorage.setItem("company", data?.CoID);
                                                                localStorage.setItem("companyname", data?.Company[0]?.CoName);
                                                                setStep(0);
                                                                window.location.href = "/";
                                                            }}>{data?.Company[0].CoName}</a></h6>
                                                        </div>
                                                        <div className="user-progress d-flex align-items-center gap-1">
                                                            <i className='bx bx-dots-horizontal-rounded'></i>
                                                        </div>
                                                    </div>
                                                </li>);
                                            })}
                                        </ul>
                                    </div> :
                                    <div>
                                        <div className="app-brand justify-content-center">
                                            <a href="/" className="app-brand-link gap-2">
                                                <img src="assets/BravoLogo.png" height="60" />
                                            </a>
                                        </div>
                                        <h4 className="mb-2">Welcome</h4>
                                        <p className="mb-4">Please sign-in to your account</p>

                                        {error !== "" && <div className="alert alert-danger" role="alert">{error}</div>}
                                        <form id="formAuthentication" className="mb-3" action="" method="POST" onSubmit={() => loginFunc}>
                                            <div className="mb-3">
                                                <label className="form-label">Email or Username</label>
                                                <input
                                                    type="text"
                                                    className="form-control"
                                                    id="email"
                                                    name="email-username"
                                                    placeholder="Enter your email or username"
                                                    autoFocus
                                                    value={username}
                                                    onChange={(e) => setUsername(e.target.value)}
                                                />
                                            </div>
                                            <div className="mb-3 form-password-toggle">
                                                <div className="d-flex justify-content-between">
                                                    <label className="form-label" >Password</label>
                                                    <a href="/fgt">
                                                        <small>Forgot Password?</small>
                                                    </a>
                                                </div>
                                                <div className="input-group input-group-merge">
                                                    <input
                                                        type="password"
                                                        id="password"
                                                        className="form-control"
                                                        name="password"
                                                        placeholder="&#xb7;&#xb7;&#xb7;&#xb7;&#xb7;&#xb7;&#xb7;&#xb7;&#xb7;&#xb7;&#xb7;&#xb7;"
                                                        aria-describedby="password"
                                                        value={password}
                                                        onChange={(e) => setPassword(e.target.value)}
                                                    />
                                                    <span className="input-group-text cursor-pointer"><i className="bx bx-hide"></i></span>
                                                </div>
                                            </div>
                                            <div className="mb-3">
                                                <div className="form-check">
                                                    <input className="form-check-input" type="checkbox" id="remember-me" />
                                                    <label className="form-check-label" > Remember Me </label>
                                                </div>
                                            </div>
                                            <div className="mb-3 text-center">
                                                {loading === true ?
                                                    <div className="spinner-border text-primary" role="status">
                                                        <span className="visually-hidden">Loading...</span>
                                                    </div> :
                                                    <button className="btn btn-primary d-grid w-100" type="submit" onClick={(e) => {
                                                        e.preventDefault();
                                                        loginFunc();
                                                    }}>Sign in</button>}
                                            </div>
                                        </form>

                                        <p className="text-center">
                                            <span>Need an account contact admin </span>
                                            <a href="mailto:info@admin.com">
                                                <span>support@bravoghana.com</span>
                                            </a>
                                        </p>
                                    </div>
                                }
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

export default Index;