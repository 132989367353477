const baseUrl="https://api.bravoghana.com/api/v1.0/admin/";
// const baseUrl="http://localhost:28090/api/v1.0/admin/";

export const noDataHttp =async(url:string)=>{
    return await new Promise((resolve,reject)=>{
        const headers:any={
            'Content-Type': 'application/json',
        }

        headers['Authentication']=`Basic ${localStorage.getItem("token")}`;
        fetch(`${baseUrl}${url}`, {
            method:'GET',
            headers:headers,
        })
        .then(response=>response.json())
        .then(response=>{
            resolve(response);
        })
        .catch(error=>{
            console.log(error);
            reject(error.getMessage);
        })
    });
}

export const dataHttp =async(urlPath:string,data:Object,auth:boolean=true,methodType:string)=>{
    return await new Promise((resolve,reject)=>{
        const headers:any={
            'Content-Type': 'application/json',
        }

        if(auth==true)
            headers['Authorization']=`Basic ${localStorage.getItem("token")}`;
        fetch(`${baseUrl}${urlPath}`, {
            method:methodType,
            headers:headers,
            body: JSON.stringify(data),
        })
        .then(response=>response.json())
        .then(response=>{
            if(response.code===403){
                localStorage.removeItem("loggedIn");
                localStorage.removeItem("token");
                localStorage.removeItem("user");
                localStorage.removeItem("companies");
                window.location.href="/";
            }
            resolve(response);
        })
        .catch(error=>{
            reject(error.getMessage);
        })
    });
}


export function AddLibrary(urlOfTheLibrary:string) {
    const script = document.createElement('script');
    script.src = urlOfTheLibrary;
    script.async = true;
    document.body.appendChild(script);
  }