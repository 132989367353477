import { FC, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { AddLibrary, dataHttp, noDataHttp } from "../HttpCon";
import Aside from '../components/Aside';
import TopMenu from '../components/TopMenu';
import Footer from "../components/Footer";
import moment from "moment";

declare global {
    interface Window {
        pieGraph: any;
        lineGraph: any;
    }
}
const Index: FC = () => {
    const navigate = useNavigate();
    const [error, setError] = useState("");
    const [loading, setLoading] = useState(false);
    const activeShop = localStorage.getItem("activeShop") || "";
    const [report, setReport] = useState<any>({});
    const currency = <span>&#8373;</span>;
    const monthLabels = ["", "Jan", 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
    const [reportList, setReportList] = useState<any>({});

    useEffect(() => {
        loadData();
    }, []);

    const loadData = () => {
        setLoading(true);
        const payloadData: any = {};
        payloadData['CoID'] = localStorage.getItem("company");
        if (!["", null].includes(activeShop))
            payloadData['ShopID'] = activeShop;

        dataHttp('dashboard', payloadData, true, 'POST').then((results: any) => {
            setReport(results);
            const year = Number(moment().format("Y"));
            const dataSeries: any = [
                { name: String(year), data: Array(12).fill(0) },
                { name: String(year - 1), data: Array(12).fill(0) }
            ];
            const months = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];


            setTimeout(() => {
                results.monthonmonth.map((data: any) => {
                    dataSeries[0].data[data.month - 1] = parseFloat(data.amount);
                });

                results.monthonmonthpast.map((data: any) => {
                    dataSeries[1].data[data.month - 1] = parseFloat(data.amount);
                });
                console.log(dataSeries);
                // window.pieGraph('growthChart1',Number(results.monthly_group));
                // const lbs=results.monthly.map((m:any)=>monthLabels[Number(m.month)]);
                // window.lineGraph(results.monthly.map((m:any)=>Number(m.amount)),'profileReportChart1',lbs,80);
                window.lineGraph(dataSeries, 'totalRevenueChart1', months, 360);
            }, 500);
        }).finally(() => {
            setLoading(false);
        });

        dataHttp('report', {
            ShopID: activeShop,
            CoID: localStorage.getItem("company")
        }, true, 'POST').then((results: any) => {
            setReportList(results);
            console.log(results);
        }).finally(() => {
            setLoading(false);
        });
    }


    return (
        <>
            <div className="layout-wrapper layout-content-navbar">
                <div className="layout-container">

                    <Aside />

                    <div className="layout-page">

                        <TopMenu />

                        <div className="content-wrapper">

                            <div className="container-xxl flex-grow-1 container-p-y">
                                <div className="row">
                                    <div className="col-12 col-lg-8 order-2 order-md-3 order-lg-2 mb-4">
                                        <div className="card">
                                            <div className="row row-bordered g-0">
                                                <div className="col-md-12">
                                                    <h5 className="card-header m-0 me-2 pb-3">Total Revenue</h5>
                                                    <div id="totalRevenueChart1" className="px-2"></div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-12 col-md-8 col-lg-4 order-3 order-md-2">
                                        <div className="row">
                                            <div className="col-lg-6 col-md-12 col-6 mb-4">
                                                <div className="card">
                                                    <div className="card-body">
                                                        <div className="card-title d-flex align-items-start justify-content-between">
                                                            <div className="avatar flex-shrink-0">
                                                                <img
                                                                    src="../assets/img/icons/unicons/chart-success.png"
                                                                    alt="chart success"
                                                                    className="rounded"
                                                                />
                                                            </div>
                                                        </div>
                                                        <span className="fw-semibold d-block mb-1">Today</span>
                                                        <h3 className="card-title mb-2">{currency}{report?.today?.[0]?.amount ? report?.today?.[0].amount : '--.--'}</h3>
                                                        <small className="text-success fw-semibold"><i className="bx bx-up-arrow-alt"></i> +72.80%</small>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-lg-6 col-md-12 col-6 mb-4">
                                                <div className="card">
                                                    <div className="card-body">
                                                        <div className="card-title d-flex align-items-start justify-content-between">
                                                            <div className="avatar flex-shrink-0">
                                                                <img
                                                                    src="../assets/img/icons/unicons/chart-success.png"
                                                                    alt="Credit Card"
                                                                    className="rounded"
                                                                />
                                                            </div>
                                                        </div>
                                                        <span>Week</span>
                                                        <h4 className="card-title text-nowrap mb-1">{currency}{report?.week?.[0]?.amount ? report?.week?.[0].amount : '--.--'}</h4>
                                                        <small className="text-success fw-semibold"><i className="bx bx-up-arrow-alt"></i> +28.42%</small>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-6 mb-4">
                                                <div className="card">
                                                    <div className="card-body">
                                                        <div className="card-title d-flex align-items-start justify-content-between">
                                                            <div className="avatar flex-shrink-0">
                                                                <img src="../assets/img/icons/unicons/chart-success.png" alt="Credit Card" className="rounded" />
                                                            </div>
                                                        </div>
                                                        <span className="d-block mb-1">Month</span>
                                                        <h3 className="card-title text-nowrap mb-2">{currency}{report?.month?.[0]?.amount ? report?.month?.[0].amount : '--.--'}</h3>
                                                        <small className="text-success fw-semibold"><i className="bx bx-down-arrow-alt"></i> -14.82%</small>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-6 mb-4">
                                                <div className="card">
                                                    <div className="card-body">
                                                        <div className="card-title d-flex align-items-start justify-content-between">
                                                            <div className="avatar flex-shrink-0">
                                                                <img src="../assets/img/icons/unicons/chart-success.png" alt="Credit Card" className="rounded" />
                                                            </div>
                                                        </div>
                                                        <span className="fw-semibold d-block mb-1">Year</span>
                                                        <h4 className="card-title mb-2">{currency}{report?.year?.[0]?.amount ? report?.year?.[0].amount : '--.--'}</h4>
                                                        <small className="text-success fw-semibold"><i className="bx bx-up-arrow-alt"></i> +28.14%</small>
                                                    </div>
                                                </div>
                                            </div>


                                        </div>
                                    </div>
                                </div>
                                <div className="row">

                                    <div className="col-md-6 col-lg-6 col-xl-6 order-0 mb-4">
                                        <div className="card h-100">
                                            <div className="card-header d-flex align-items-center justify-content-between pb-0">
                                                <div className="card-title mb-0">
                                                    <h5 className="m-0 me-2">Order Statistics</h5>
                                                </div>
                                            </div>
                                            <div className="card-body">
                                                <div className="row mt-4 mb-5">
                                                    {reportList?.ordersBreakdown !== undefined &&
                                                        Object.keys(reportList?.ordersBreakdown[0]).map((data: any, i: number) => {
                                                            return (<div className="col text-center" key={`orders${i}`}>
                                                                <h2 className="mb-2">{reportList?.ordersBreakdown[0][data] || 0}</h2>
                                                                <span>Total {data}</span>
                                                            </div>);
                                                        })}
                                                </div>
                                                <ul className="p-0 m-0">
                                                    {reportList?.orders?.map((data: any, i: number) => {
                                                        return (<li className="d-flex mb-4 pb-1" key={`report11${i}`}>
                                                            <div className="d-flex w-100 flex-wrap align-items-center justify-content-between gap-2">
                                                                <div className="me-2">
                                                                    <h6 className="mb-0">{data.SummaryGroup.replace(/[^a-zA-Z ]/g, "")} </h6>
                                                                </div>
                                                                <div className="user-progress d-flex align-items-center gap-1">
                                                                    <span className="text-muted">GHS</span>
                                                                    <h6 className="mb-0">{Number(data.amount).toFixed(2)}</h6>
                                                                </div>
                                                            </div>
                                                        </li>);
                                                    })}
                                                </ul>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="col-md-6 col-lg-6 order-2 mb-4">
                                        <div className="card h-100">
                                            <div className="card-header d-flex align-items-center justify-content-between">
                                                <h5 className="card-title m-0 me-2">Payments</h5>

                                            </div>
                                            <div className="card-body">
                                                <ul className="p-0 m-0">
                                                    {reportList?.payments?.map((data: any, i: number) => {
                                                        return (<li className="d-flex mb-4 pb-1" key={`sdfjsd${i}`}>
                                                            <div className="d-flex w-100 flex-wrap align-items-center justify-content-between gap-2">
                                                                <div className="me-2">
                                                                    <h6 className="mb-0">{data.PaymentMeth} </h6>
                                                                </div>
                                                                <div className="user-progress d-flex align-items-center gap-1">
                                                                    <span className="text-muted">GHS</span>
                                                                    <h6 className="mb-0">{Number(data.amount).toFixed(2)}</h6>
                                                                </div>
                                                            </div>
                                                        </li>);
                                                    })}
                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>


                            <Footer />

                            <div className="content-backdrop fade"></div>
                        </div>
                    </div>
                </div>

                <div className="layout-overlay layout-menu-toggle"></div>
            </div>
            {AddLibrary('../assets/js/dashboards-analytics.js')}
        </>
    );
}

export default Index;