import { FC, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { AddLibrary, dataHttp, noDataHttp } from "../HttpCon";
import Aside from '../components/Aside';
import TopMenu from '../components/TopMenu';
import Footer from "../components/Footer";
import moment from "moment";

declare global {
    interface Window {
        sendAlert: any;
        reCreate:any;
    }
}
const Index: FC = () => {
    const navigate = useNavigate();
    const [error, setError] = useState("");
    const [loading, setLoading] = useState(false);
    const [dataSet, setDataSet] = useState([]);
    const [sDate, setSDate] = useState("");
    const [eDate, setEDate] = useState("");
    const [payload, setPayload] = useState<any>({});
    const [filterList, setFilterList] = useState<any>([]);
    const activeShop = localStorage.getItem("activeShop") || "";

    useEffect(() => {
        loadData();
    }, []);

    const loadData = () => {
        setLoading(true);
        const payloadData: any = {
            CoID: localStorage.getItem("company"),
            sDate:`${moment().format("YYYY-MM-")}01`,
            eDate:moment().format("YYYY-MM-DD")
        };
        setPayload(payloadData);
        payloadData['CoID'] = localStorage.getItem("company");
        if (!["", null].includes(activeShop))
            payloadData['ShopID'] = activeShop;
        dataHttp('discounts', payloadData, true, 'POST').then((results: any) => {
            setDataSet(results.results);
            setFilterList(results.filters);
            setTimeout(() => {
                window.sendAlert();
            }, 500);
        }).finally(() => {
            setLoading(false);
        });
    }
    

    const filterData = () => {
        window.reCreate();
        setLoading(true);
        const payloadData: any = payload;
        payloadData['CoID'] = localStorage.getItem("company");
        if (sDate !== "")
            payloadData['sDate'] = sDate;
        if (eDate !== "")
            payloadData['eDate'] = eDate;
        if (!["", null].includes(activeShop))
            payloadData['ShopID'] = activeShop;

        dataHttp('discounts', Object.fromEntries(Object.entries(payloadData).filter(([key,value])=>value!=='')), true, 'POST').then((results: any) => {
            setDataSet(results.results);
            setTimeout(() => {
                window.sendAlert();
            }, 500);
        }).finally(() => {
            setLoading(false);
        });
    }

    return (
        <>
            <div className="layout-wrapper layout-content-navbar">
                <div className="layout-container">

                    <Aside />

                    <div className="layout-page">

                        <TopMenu />

                        <div className="content-wrapper">

                            <div className="container-xxl flex-grow-1 container-p-y">
                                <div className="row">
                                    <div className="col-lg-12 mb-4 order-0">
                                    <div className="card mb-4">
                                            <div className="d-flex align-items-end row">
                                                <div className="col-sm-12">
                                                    <div className="card-body">
                                                        <h5 className="card-title text-primary">Filter</h5>
                                                        <hr />
                                                        {loading === true &&
                                                            <div className="text-center">
                                                                <div className="spinner-border text-primary mb-4" role="status">
                                                                    <p className="visually-hidden">Loading...</p>
                                                                </div>
                                                            </div>}
                                                        <p className="text-secondary  mt-4">Filtering Records</p>
                                                        <div className="row mb-4">
                                                            {filterList.map((filters: any,i:number) => {
                                                                    return (<div className="col" key={`filter${i}`}>
                                                                        <label>{filters.label}</label>
                                                                        <select
                                                                            className="form-control"
                                                                            onChange={(e) => {
                                                                                const currentPayload: any = payload;
                                                                                currentPayload[filters.column] = e.target.value;
                                                                                setPayload(currentPayload);
                                                                            }}
                                                                            value={payload[filters]}
                                                                        >
                                                                            <option value="">All {filters.label}</option>
                                                                            {filters.data.map((o: any,i:number) => <option  key={`option${i}`} value={o[Object.keys(o)[0]]}>{o[Object.keys(o)[0]]}</option>)}
                                                                        </select>
                                                                    </div>)
                                                            })}
                                                            <div className="col">
                                                                <label>Start Date</label>
                                                                <input
                                                                    type="date"
                                                                    className="form-control"
                                                                    onChange={(e) => {
                                                                        const currentPayload: any = {};
                                                                        currentPayload['sDate'] = e.target.value;
                                                                        setPayload({ ...payload, ...currentPayload });
                                                                    }}
                                                                    value={payload.sDate}
                                                                />
                                                            </div>
                                                            <div className="col">
                                                                <label>End Date</label>
                                                                <input
                                                                    type="date"
                                                                    className="form-control"
                                                                    onChange={(e) => {
                                                                        const currentPayload: any = {};
                                                                        currentPayload['eDate'] = e.target.value;
                                                                        setPayload({ ...payload, ...currentPayload });
                                                                    }}
                                                                    value={payload.eDate}
                                                                />
                                                            </div>
                                                            <div className="col-auto">
                                                                <label>.</label>
                                                                <button className="btn btn-primary d-grid w-100" type="button" onClick={() => filterData()}>Filter</button>
                                                            </div>
                                                            <div className="col-auto">
                                                                <label>.</label>
                                                                <button className="btn btn-primary d-grid w-100" type="button" onClick={() => window.location.href = "/discount"}>Show All</button>
                                                            </div>
                                                        </div>

                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        
                                        <div className="card">
                                            <div className="d-flex align-items-end row">
                                                <div className="col-sm-12">
                                                    <div className="card-body">
                                                        {loading === true &&
                                                            <div className="text-center">
                                                                <div className="spinner-border text-primary mb-4" role="status">
                                                                    <p className="visually-hidden">Loading...</p>
                                                                </div>
                                                            </div>}
                                                        
                                                        <table id="example" className="stripe" style={{ width: "100%" }}>
                                                            <thead>
                                                                <tr>
                                                                    <th>Date</th>
                                                                    <th>Order Total</th>
                                                                    <th>Cashier</th>
                                                                    <th>Dis. Amount</th>
                                                                </tr>
                                                            </thead>
                                                            <tbody>
                                                                {dataSet.map((data: any,index:number) => {
                                                                    return (<tr key={index}>
                                                                        <td>{data.OrderDate}</td>
                                                                        <td>{Number(data.OrderAmount).toFixed(2)}</td>
                                                                        <td>{data.DiscountBy}</td>
                                                                        <td>{Number(data.DiscountAmount).toFixed(2)}</td>
                                                                    </tr>);
                                                                })}
                                                            </tbody>
                                                        </table>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>


                            <Footer />

                            <div className="content-backdrop fade"></div>
                        </div>
                    </div>
                </div>

                <div className="layout-overlay layout-menu-toggle"></div>
            </div>
            {AddLibrary('../assets/js/dashboards-analytics.js')}
        </>
    );
}

export default Index;