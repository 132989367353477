import React from 'react';
import logo from './logo.svg';
import './App.css';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import Index from './pages/Index';
import Home from './pages/Home';
import Stock from './pages/Stock';
import Sales from './pages/Sales';
import Discount from './pages/Discounts';
import Payment from './pages/Payments';
import Fgt from './pages/Fgt';
import Reset from './pages/Reset';
import NewUser from './pages/NewUser';

function App() {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={localStorage.getItem("loggedIn")===null?<Index />:<Home />} />
        <Route path="/stock" element={<Stock />} />
        <Route path="/sales" element={<Sales />} />
        <Route path="/payment" element={<Payment />} />
        <Route path="/discount" element={<Discount />} />
        <Route path="/fgt" element={<Fgt />} />
        <Route path="/reset" element={<Reset />} />
        <Route path="/newpassword" element={<NewUser />} />
      </Routes>
    </BrowserRouter>
  );
}

export default App;
